import React from 'react';
import './TeachingExperience.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';

import iiitdIcon from './img/teaching_experience/iiitd.png';
import uscIcon from './img/teaching_experience/usc.png';

// description of teaching experiences
const teachingExperiences = [
	{
		image: uscIcon,
		imageLink: null,
		title: 'Course Producer, CSCI 270:  Introduction to Algorithms and Theory of Computing',
		location: 'University of Southern California, Los Angeles, United States',
		date: 'Aug 2020 - May 2021',
		links: [],
		description: 
		<span>
			Responsible for co-leading discussions, holding office hours and grading exams.
		</span>
	},
	{
		image: iiitdIcon,
		imageLink: null,
		title: 'Teaching Assistant, CSE 102: Data Structures and Algorithms',
		location: 'Indraprastha Institute of Information Technology, Delhi, India',
		date: 'Jan 2018 - April 2018',
		links: [],
		description: 
		<span>
			Led two weekly tutorials, graded submissions, assisted in labs and held discussion sessions.
		</span>
	},
];

class ResponsiveCard extends React.Component{
	render(){
		const image = (
			<Col xs={12} md={2} className="project-image-container">
				<table>
					<tbody>
						<tr>
							<td><a href={this.props.imageLink} target="_blank"><Image src={this.props.image} className="project-image" /></a></td>
						</tr>
					</tbody>
				</table>
			</Col>
		);

		const card = (
			<Col>
				<CardGroup>
					<Card border="light" bg="light">
					  <Card.Body>
					    <Card.Title>{this.props.title}</Card.Title>
					    <Card.Subtitle className="mb-2 text-muted">{this.props.location}, <i>{this.props.date}</i></Card.Subtitle>
					    <Card.Text>
					      {this.props.children}
					    </Card.Text>
					    {
					    	this.props.links.map((link, index) => (
					    		<Card.Link key={index} href={link.address} target="_blank">{link.displayText}</Card.Link>
					    	))
					    }
					  </Card.Body>
					</Card>
				</CardGroup>
			</Col>
		);

		return (
			<Container className="margin-top-teaching-experience border-left">
				<Row>
					{image}
					{card}
				</Row>
			</Container>
		);
	}
}

class TeachingExperience extends React.Component{
	render(){
		return (
			<Container className="container-teaching-experience-padding">
				<h4>Teaching Experience</h4>
				{
					teachingExperiences.map((project, index) => (
						<ResponsiveCard
							key={index}
							id={index}
							image={project.image}
							imageLink={project.imageLink}
							title={project.title}
							location={project.location}
							date={project.date}
							links={project.links}
						>
							{project.description}
						</ResponsiveCard>
					))
				}

			</Container>
		);
	}
}

export default TeachingExperience;