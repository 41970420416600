import React from 'react';
import './Projects.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';

import internetIcon from './img/projects/internet_icon.png';
import kernelIcon from './img/projects/kernel_icon.png';
import smashballWalkthrough from './img/projects/smashball_walkthrough.gif';
import compostMakingWalkthrough from './img/projects/compost_making_walkthrough.gif';
import lpSolverIcon from './img/projects/lp_solver_icon.png';
import dblpIcon from './img/projects/dblp_icon.png';
import cpIcon from './img/projects/cp_icon.png';
import meshManagementIcon from './img/projects/mesh_management_icon.png';

// description of projects
const projects = [
	{
		image: cpIcon,
		imageLink: 'https://github.com/hasan-kamal/Competitive-Programming',
		title: 'Competitive Programming',
		subtitle: '',
		links: [ {address: 'https://github.com/hasan-kamal/Competitive-Programming', displayText: 'Link to GitHub repo'} ],
		description: 
		<span>
			This repository contains my solutions to algorithmic programming problems from different online platforms / books. This is an ongoing work in-progress!
		</span>
	},
	{
		image: lpSolverIcon,
		imageLink: 'https://github.com/hasan-kamal/Linear-Program-Solvers',
		title: 'Linear Program (LP) Solvers',
		subtitle: '',
		links: [ {address: 'https://github.com/hasan-kamal/Linear-Program-Solvers', displayText: 'Link to GitHub repo'} ],
		description:
		<span>
		Implemented <b>Simplex</b> and <b>Primal-Dual Interior Point</b> algorithms in Python for solving linear programs (LPs).
		Apart from implementing solvers, I have:
		<ul>
			<li>
				Incorporated utility method to convert a given max-flow (network flow) problem to its corresponding LP.
			</li>
			<li>
				Tested and verified both solver implementations on concrete max-flow instances. 
			</li>
		</ul>
		</span>
	},
	{
		image: internetIcon,
		imageLink: null,
		title: 'Mini-Internet Development',
		subtitle: 'Course project for CSCI 551: Computer Networking, University of Southern California',
		links: [],
		description:
		<span>
		Developed key network components of a functioning mini-Internet in C.
		<ul>
			<li>
				Implemented a <b>router</b> in software that receives Ethernet frames, extracts its IP payload, and forwards it based on a static routing table using longest prefix matching.
				Implemented basic functionality of Ethernet (link-layer), Address Resolution Protocol (<b>ARP</b>), Internet Protocol (<b>IP</b>) and Internet Control Message Protocol (<b>ICMP</b>).				
			</li>
			<li>
				Implemented sliding-window <b>Transmission Control Protocol (TCP)</b> from scratch — enabling end-to-end reliable and in-order delivery of data on top of an underlying unreliable IP network.
				Incorporated receiver-limited flow control and basic connection teardown state machine (handling FIN segments, etc).
			</li>
		</ul>
		</span>
	},
	{
		image: kernelIcon,
		imageLink: null,
		title: 'Operating System Kernel Development',
		subtitle: 'Course project for CSCI 402: Operating Systems, University of Southern California',
		links: [],
		description:
		<span>
		Programmed key kernel-level components in C for Weenix, a Unix-like operating system. Implemented following functionalities on a non-preemptive single-CPU (x86) architecture:
		<ul>
			<li><b>Processes: </b> kernel process creation, waiting and destruction.</li>
			<li><b>Scheduler: </b> kernel process sleep, wakeup, broadcast, cancel, cancellable sleep.</li>
			<li><b>Threads: </b> kernel thread creation, cancellation and destruction.</li>
			<li><b>Mutex: </b> kernel mutex initialization, lock, cancellable lock and unlock.</li>
			<li><b>Virtual Filesystem: </b> pathname resolution + read, write, open, close, dup, dup2, mkdir, rmdir system calls.</li>
			<li><b>Virtual Memory: </b> process-level address space (virtual memory map), page fault handling, fork system call.</li>
		</ul>
		Team size: 3 (along with <a href="https://www.linkedin.com/in/mahak-bansal/" target="_blank">Mahak Bansal</a> and <a href="https://www.linkedin.com/in/shambhavi-jagtap/" target="_blank">Shambhavi Jagtap</a>)
		</span>
	},
	{
		image: smashballWalkthrough,
		imageLink: 'https://github.com/hasan-kamal/Smashball',
		title: 'Smashball',
		subtitle: 'A fun physics-based mobile game developed in C++',
		links: [ {address: 'https://github.com/hasan-kamal/Smashball', displayText: 'Link to GitHub repo'} ],
		description: 
			<span>
				<br />
					<b><i>Demo!</i></b>
				<br />

				<div className='embed-container'><iframe src='https://www.youtube.com/embed/vHljHNpq_Mg' frameborder='0' allowfullscreen></iframe></div>
				
				<br />

				<ul>
					<li>Smashball is my personal project, a fun to play game that is both <b>realistic</b> (in terms of game quality/physics) and <b>visually aesthetic</b>. </li>
					<li>The objective for the player is simple: score as many points as possible under a minute by (continuously) flicking a ball towards a target board.</li>
					<li>Since there are multiple stages with different kinds of boards and obstructions, the optimal strategies would be different for each stage.</li>
					<li>From a design perspective, visual design of the game (and of all objects in it) was kept minimalistic to make it visually appealing.</li>
					<li>From a development perspective, all objects were implemented in a clean class hierarchy so that the code that lays out levels is generic, and thus level prototyping is quick.</li>
					<li>Smashball was developed in C++ with <a href="https://www.cocos.com/en/">Cocos2d-x</a> game engine, allowing the game to be cross-platform.</li>
					<li>I used <a href="https://box2d.org">Box2d</a> physics library for physics simulation (Box2d is known to provide very realistic physics simulation).</li>
					<li>I have implemented some cool game objects in Smashball, from <b><i>spiky boards</i></b> (yikes!) to <b><i>wormholes</i></b> that can teleport the ball <i>in situ</i>!</li>
				</ul>

			You can view Smashball repo on GitHub, or better yet play it yourself (link to download APK installer for Android devices can be found in README of the repo).
			</span>
	},
	{
		image: compostMakingWalkthrough,
		imageLink: 'https://github.com/hasan-kamal/CompostMaking',
		title: 'CompostMaking',
		subtitle: 'A mobile game developed in C++ for vocational training of autistic kids',
		links: [ {address: 'https://github.com/hasan-kamal/CompostMaking', displayText: 'Link to GitHub repo'} ],
		description: 
			<span>
				<br />
					<b><i>Demo!</i></b>
				<br />

				<div className='embed-container'><iframe src='https://www.youtube.com/embed/zhfvoEj43Pw' frameborder='0' allowfullscreen></iframe></div>
				
				<br />
				<ul>
					<li>CompostMaking is an Android game that I developed in collaboration with NGO <a href="http://www.tamana.org">Tamana organization</a> for challenged kids that teaches them how to create compost using household waste.</li>
					<li>The main goal of the game is to inculcate the skill of differentiation between biodegradable and non-biodegradable items.</li>
					<li>The game consists of multiple levels (with increasing difficulties) in which the player is supposed to decide whether the displayed object is biodegradable or not.</li>
					<li>If the displayed item is biodegradable (such as leaves), then the player is supposed to drag-and-drop the object onto a <b>green tray</b>; otherwise, if it is non-biodegradable (such as plastic bag), then the player is supposed to drag-and-drop the object onto a <b>blue tray</b>.</li>
					<li>
						I have incorporated following features in CompostMaking, specifically keeping in mind that the target audience comprises of a special child (the player/trainee) and a teacher (the trainer):
						<ol>
							<li>A <b>very simple interface</b> has been kept deliberately so that the player has least amount of distraction.</li>
							<li>An <b>in-game character</b> has been added whose expressions and sounds provide feedback to the player about his/her progress (simple smiley faces are also used to convey the result of each classification attempt made by player).</li>
							<li>After a period of inactivity, the in-game character <b>reaches out to the player</b> to bring their attention back to the game (special kids tend to lose attention and stop playing the game otherwise).</li>
							<li>After each level, a <b>reward screen</b> has been added in which players can either pop colorful balloons or see colorful fireworks (this is intended to be a reward incentive for players).</li>
							<li><b>Statistics</b> regarding the gameplay (# of incorrect attempts, etc) are saved into a local database that can be monitored by the trainer inside the application itself (click on bottom-left database button on the homescreen).</li>
							<li><b>Hindi</b> language support has also been added (go to Settings from homescreen and turn off English).</li>
						</ol>
					</li>					
				</ul>

			You can view CompostMaking repo on GitHub, or test it out yourself (link to download APK installer for Android devices can be found in README of the repo).
			</span>
	},
	{
		image: meshManagementIcon,
		imageLink: null,
		title: 'View-dependent Mesh Management',
		subtitle: 'Course project for Computer Graphics course',
		links: [],
		description:
		<span>
		To support rendering of very large meshes in <b>OpenGL/C++</b> (where mesh cannot fit in RAM and/or GPU), we:
		<ul>
			<li>Implemented mesh division using <b>uniform spatial subdivision</b> and <b>k-d trees</b>.</li>
			<li>Enabled <b>view-dependent</b> and <b>out-of-core rendering</b> of large meshes.</li>
			<li>Tested our code on large IIIT-D mesh (&sim;2GB).</li>
		</ul>
			Team size: 3 (along with <a href="https://github.com/kushagra7589" target="_blank">Kushagra Arora</a> and <a href="https://www.linkedin.com/in/gaurav-gehlot/" target="_blank">Gaurav Gehlot</a>)
		</span>
	},
	{
		image: dblpIcon,
		imageLink: 'https://github.com/hasan-kamal/DBLP-Query-Engine',
		title: 'DBLP Query Engine',
		subtitle: '',
		links: [ {address: 'https://github.com/hasan-kamal/DBLP-Query-Engine', displayText: 'Link to GitHub repo'} ],
		description: 
		<span>
			Led team of 2 (comprising me and <a href="https://github.com/luv09" target="_blank">Luv Sharma</a>) to create a <b>GUI-equipped</b> query engine in Java/Swing for <b>DBLP bibliography</b> (∼2.6GB).
			We added the following functionalities to the application:
			<ol>
				<li>
					Search query on publication title or author name with option to filter/sort output via date, relevance.
				</li>
				<li>
					Query to find authors having more than a given number of publications (handled alias names).
				</li>
			</ol>
		</span>
	}
];

class ResponsiveCard extends React.Component{
	render(){
		const image = (
			<Col xs={12} md={2} className="project-image-container">
				<table>
					<tbody>
						<tr>
							<td><a href={this.props.imageLink} target="_blank"><Image src={this.props.image} className="project-image" /></a></td>
						</tr>
					</tbody>
				</table>
			</Col>
		);

		const card = (
			<Col>
				<CardGroup>
					<Card border="light" bg="light">
					  <Card.Body>
					    <Card.Title>{this.props.title}</Card.Title>
					    <Card.Subtitle className="mb-2 text-muted">{this.props.subtitle}</Card.Subtitle>
					    <Card.Text>
					      {this.props.children}
					    </Card.Text>
					    {
					    	this.props.links.map((link, index) => (
					    		<Card.Link key={index} href={link.address} target="_blank">{link.displayText}</Card.Link>
					    	))
					    }
					  </Card.Body>
					</Card>
				</CardGroup>
			</Col>
		);

		return (
			<Container className="margin-top-projects border-left">
				<Row>
					{image}
					{card}
				</Row>
			</Container>
		);
	}
}

class Projects extends React.Component{
	render(){
		return (
			<Container className="container-projects-padding">
				<h4>Projects</h4>
				{
					projects.map((project, index) => (
						<ResponsiveCard
							key={index}
							id={index}
							image={project.image}
							imageLink={project.imageLink}
							title={project.title}
							subtitle={project.subtitle}
							links={project.links}
						>
							{project.description}
						</ResponsiveCard>
					))
				}

			</Container>
		);
	}
}

export default Projects;