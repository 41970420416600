import 'babel-polyfill';
import 'bootstrap/dist/css/bootstrap.css';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import Container from 'react-bootstrap/Container';
import Cover from './Cover'
import Experiences from './Experience'
import TeachingExperience from './TeachingExperience'
import Projects from './Projects'
import Miscellaneous from './Miscellaneous'
import Footer from './Footer'

ReactDOM.render(
	<Container>
		<article>
			<Cover />
			<Experiences />
			<TeachingExperience />
			<Projects />
			<Miscellaneous />
			<Footer />
		</article>
  </Container>,
  document.getElementById('root')
);