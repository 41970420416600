import React from 'react';
import './Miscellaneous.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';

// description of miscellaneous entries
const entries = [
	{
		title: 'Cuisine Prediction',
		links: [ { displayText: 'View on GitHub', address: 'https://github.com/hasan-kamal/Cuisine-Prediction' } ],
		description: 
			<span>
			Developed a prediction system that uses recipe-ingredient data <a href="https://www.kaggle.com/c/whats-cooking">from this Kaggle challenge</a> to <b>predict the cuisine of a given recipe</b>.
			Implemented and evaluated following techniques:
			<ol>
				<li>Simple heuristics</li>
				<li>Neural Network, SVM with 1-hot encoding</li>
				<li>Neural Network, SVM, GRU with <a href="https://github.com/aditya-grover/node2vec">node2vec</a> embeddings</li>
				<li>Clustering-based heuristics</li>
			</ol>
			See GitHub repo for results.
			</span>
	},
	{
		title: 'Mutual Fund prediction with Recurrent Neural Networks',
		links: [  ],
		description: 
			<span>
			<i>Research project under <a href="https://www.iiitd.ac.in/angshul" target="_blank">Dr Angshul Majumdar</a></i>
			<br />
			Implemented and evaluated long-horizon mutual fund closing-price prediction system(s) using LSTM/GRUs in Keras.
			</span>
	},
	{
		title: 'Neural Network Implementation',
		links: [ { displayText: 'View on GitHub', address: 'https://github.com/hasan-kamal/Neural-Network-Implementation' } ],
		description: 
			<span>
			A vectorized implementation of fully-connected neural network(s) in NumPy, part of my classwork for <em>Machine Learning</em> course
			</span>
	},
	{
		title: 'Chat Application',
		links: [ { displayText: 'View on GitHub', address: 'https://github.com/hasan-kamal/Chat-Application' } ],
		description: 
			<span>
			A multi-threaded chat server and client implementation in Java, part of my classwork for <em>Computer Networks</em> course
			</span>
	},
	{
		title: 'Telnet Client',
		links: [ { displayText: 'View on GitHub', address: 'https://github.com/hasan-kamal/Telnet-Client' } ],
		description: 
			<span>
			A telnet client implementation in C, part of my classwork for <em>Computer Networks</em> course
			</span>
	},
	{
		title: 'TCP over UDP',
		links: [ { displayText: 'View on GitHub', address: 'https://github.com/hasan-kamal/TCP-over-UDP' } ],
		description: 
			<span>
			An implementation of a custom TCP-like reliable data transport protocol over UDP in Java, part of my classwork for <em>Computer Networks</em> course
			</span>
	},
	{
		title: '2001: A Space Odyssey — The Game',
		links: [ {displayText: 'View Gameplay', address: 'https://drive.google.com/file/d/1A2z-vsUiGtDj7XqS8BUXSBNuV82QlSnE/view?usp=sharing'} ],
		description: 
			<span>
			Designed an adventure game based on the classic sci-fi movie <em>2001: A Space Odyssey</em> using Unreal Engine.
			Co-designed with <a href="https://www.linkedin.com/in/nishantsinha15/" target="_blank">Nishant Sinha</a>.
			</span>
	}
];

class ResponsiveEntry extends React.Component{
	render(){
		const card = (
			<Col>
				<CardGroup>
					<Card border="light" bg={this.props.id % 2 == 0 ? 'light' : null}>
					  <Card.Body>
					    <Card.Title>{this.props.title}</Card.Title>
					    <Card.Text>
					      {this.props.children}
					    </Card.Text>
					    {
					    	this.props.links.map((link, index) => (
					    		<Card.Link key={index} href={link.address} target="_blank">{link.displayText}</Card.Link>
					    	))
					    }
					  </Card.Body>
					</Card>
				</CardGroup>
			</Col>
		);

		return (
			<Container className="small-margin-top">
				<Row>
					{card}
				</Row>
			</Container>
		);
	}
}

class Miscellaneous extends React.Component{
	render(){
		return (
			<Container className="container-miscellaneous-padding">
				<h4>More</h4>
				<p className="miscellaneous-opening-text">Other works of mine, including selected classwork (see <a href="https://github.com/hasan-kamal?tab=repositories" target="_blank">my GitHup repositories</a> for more):</p>
				{
					entries.map((project, index) => (
						<ResponsiveEntry
							key={index}
							id={index}
							title={project.title}
							links={project.links}
						>
							{project.description}
						</ResponsiveEntry>
					))
				}

			</Container>
		);
	}
}

export default Miscellaneous;