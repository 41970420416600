import React from 'react';
import './Cover.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import coverPhoto from './img/cover.jpeg';
import emailIcon from './img/icons_unround/email_icon.png';
import githubIcon from './img/icons_unround/github_icon.png';
import linkedInIcon from './img/icons_unround/linkedin_icon.png';
import resumeIcon from './img/icons_unround/resume_icon.png';

import resumePdf from './resources/Resume, Hasan Kamal.pdf';

class Introduction extends React.Component{
	render(){
		return (
			<Col xs={this.props.xs} md={this.props.md} style={{'padding': '4% 4% 0% 4%'}}>
				<h1 className="display-4">Hi, I'm Hasan</h1>
			  	<p style={{textAlign: 'justify'}}>
				  	I'm a Software Engineer at <mark>Google</mark>, San Francisco Bay Area, California.
				  	I received my master's degree in Computer Science from the <a href="https://www.cs.usc.edu/" target="_blank"><mark>University of Southern California</mark></a>, 
				  	and my bachelor's degree in Computer Science from <a href="https://iiitd.ac.in/" target="_blank"><mark>IIIT-Delhi</mark></a>.
				  	I enjoy solving problems, developing stuff, and learning new things. 

				  	<br />
				  	<br />
				  	Outside of work, I can usually be found strumming my guitar, playing a sport (table tennis, soccer and newly swimming!) or participating in competitive programming contests.

				  	<br />
				  	<br />
				  	You can check out some of my work below, or head over to <a href="https://github.com/hasan-kamal" target="_blank">my GitHub profile</a>.
			  	</p>
			</Col>
		);
	}
}

class Contact extends React.Component{
	render(){
		const contacts = [
			{desc: 'Email', link: 'mailto:hasankam97@gmail.com', icon: emailIcon},
			{desc: 'Github', link: 'https://github.com/hasan-kamal', icon: githubIcon},
			{desc: 'Linkedin', link: 'https://www.linkedin.com/in/hasan-kamal/', icon: linkedInIcon},
			// {desc: 'Resume', link: resumePdf, icon: resumeIcon}
		];
		const numContacts = Object.keys(contacts).length;

		return (
			<Container className="margin-top contact-container">
				<Row>
					{
						contacts.map((contact) => (
							<Col xs={12 / numContacts} className="contact-col" key={contact.desc} >
								<a href={contact.link} target="_blank"><Image src={contact.icon} alt={contact.desc} title={contact.desc} className="contact-icon" /></a>
							</Col>
						))
					}
				</Row>
			</Container>
		);
	}
}

class PhotoContactDisplay extends React.Component{
	render(){
		return (
			<Col xs={this.props.xs} md={this.props.md}>
				<div className="photo-contact-container">
					<Image src={coverPhoto} roundedCircle className="padding cover-image" />
					<Contact />
				</div>
			</Col>
		);
	}
}


class Cover extends React.Component{
	render(){
		return (
			<Container className="cover-container">
				<Row>
					<Introduction xs={{span: 12, order: 2}} md={{span: 8, order: 1}}  />
					<PhotoContactDisplay xs={{span: 12, order: 1}} md={{span: 4, order: 2}}  />
				</Row>
		  </Container>
		);
	}
}

export default Cover;